import React, { FC } from 'react';
import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Spin,
    AntdList,
    Typography,
} from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MultiFileUploader from '../../../multi-file-uploader';
import { UploadFile } from 'antd/lib/upload/interface';
import { useDictionarySelectProps } from '../../../../hooks/preliminary-application/useDictionarySelectProps';
import ProcedureDescription from '../../procedure-description';
import { useMediaQuery } from 'react-responsive';

const { Text } = Typography;

interface IPreliminaryApplicationInfoProps {
    isLoading: boolean;
    formProps: any;
    handleFinish: (values: any) => Promise<void>;
    fileList: UploadFile<any>[]; // Зроблено обов'язковим
    handleFileChange: (newFileList: UploadFile[]) => void; // Зроблено обов'язковим
    uploaded: UploadFile<any>[];
    isReadOnlyForm: boolean;
    isFormProcessing: boolean;
}

export const PreliminaryApplicationInfo: FC<
    IPreliminaryApplicationInfoProps
> = ({
    formProps,
    isLoading,
    handleFinish,
    fileList = [],
    handleFileChange,
    uploaded,
    isReadOnlyForm,
    isFormProcessing,
}) => {
    const { t } = useTranslation();
    const { applicantProps, procedureProps, innProps, manufacturerProps } =
        useDictionarySelectProps();
    const isMob = useMediaQuery({ query: '(max-width: 767px)' });

    const getValueFromEvent = (fileList: UploadFile[]): UploadFile[] => {
        return fileList.map(file => ({
            uid: file.uid,
            name: file.name,
            status: file.status,
            url: file.url,
            originFileObj: file.originFileObj,
        }));
    };

    const validateFiles = (_: any, value: UploadFile[]) => {
        if (!value || value.length === 0) {
            return Promise.reject(
                new Error(
                    t(
                        'preliminary-applications.message.please_upload_your_files'
                    )
                )
            );
        }

        const allowedTypes = ['application/pdf'];
        const maxSizeMB = 10;

        for (const file of value) {
            if (file.originFileObj) {
                const { type, size, name } = file.originFileObj;

                if (!allowedTypes.includes(type)) {
                    return Promise.reject(
                        new Error(
                            `${name} ${t(
                                'preliminary-applications.message.has_an_incorrect_file_type'
                            )}`
                        )
                    );
                }

                if (size / 1024 / 1024 >= maxSizeMB) {
                    return Promise.reject(
                        new Error(
                            `${name} ${t(
                                'preliminary-applications.message.exceeds_maxsize',
                                { maxSize: maxSizeMB }
                            )}`
                        )
                    );
                }
            }
        }

        return Promise.resolve();
    };

    const {
        reg_num = '',
        reg_date = null,
        is_ectd = false,
        is_electronic_form = false,
        is_mibp = false,
        medication_name = '',
        release_form = '',
        certificate = '',
        applicant_id = '',
        procedure_id = '',
        state = '',
        state_id = '',
        procedure_desc = '',
        reject_reason = '',
        notification_email = '',
        ct_code = '',
        ct_version = '',
        ct_applicant_id = '',
        sponsor_id = '',
        amendment_id = '',
        international_name_id = '',
    } = formProps?.initialValues || {};

    const manufacturers = formProps?.initialValues?.manufacturer_id;
    const manufacturerIds =
        manufacturers?.map((manufacturer: any) => manufacturer.id) ?? [];

    return (
        <Spin spinning={isLoading || isFormProcessing}>
            <Form
                {...formProps}
                initialValues={{
                    reg_num,
                    reg_date: dayjs(reg_date, 'DD.MM.YYYY') ?? null,
                    is_ectd,
                    is_electronic_form,
                    is_mibp,
                    medication_name,
                    release_form,
                    certificate,
                    applicant_id,
                    procedure_id,
                    state_id,
                    procedure_desc,
                    reject_reason,
                    notification_email,
                    ct_code,
                    ct_version,
                    ct_applicant_id,
                    sponsor_id,
                    amendment_id,
                    international_name_id,
                    manufacturer_id: manufacturerIds,
                }}
                layout="vertical"
                onFinish={handleFinish}
                disabled={isReadOnlyForm || isFormProcessing}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={t('preliminary-applications.fields.reg_num')}
                            name="reg_num"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.reg_date'
                            )}
                            name="reg_date"
                        >
                            <DatePicker
                                format="DD.MM.YYYY"
                                style={{ width: '100%' }}
                                disabled={isReadOnlyForm}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.electronic_form'
                            )}
                            name="is_electronic_form"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={3}>
                        <Form.Item
                            label={t('preliminary-applications.fields.ectd')}
                            name="is_ectd"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={3}>
                        <Form.Item
                            label={t('preliminary-applications.fields.mibp')}
                            name="is_mibp"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.medication_name'
                            )}
                            name="medication_name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.release_form'
                            )}
                            name="release_form"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.certificate'
                            )}
                            name="certificate"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.applicant'
                            )}
                            name="applicant_id"
                        >
                            <Select {...applicantProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.procedure'
                            )}
                            name="procedure_id"
                        >
                            <Select {...procedureProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.active_ingredient_inn'
                            )}
                            name="international_name_id"
                        >
                            <Select {...innProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ display: 'none' }}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.manufacturers'
                            )}
                            name="manufacturer_id"
                        >
                            <Select mode="multiple" {...manufacturerProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.notification_email'
                            )}
                            name="notification_email"
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminary-applications.fields.comments'
                            )}
                            name="reject_reason"
                        >
                            <Input.TextArea readOnly size={'large'} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={
                                t(
                                    'preliminary-applications.fields.procedure_desc'
                                ) + ' *'
                            }
                            name="procedure_desc"
                        >
                            <Input.TextArea size={'large'} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} style={isMob ? { order: 2 } : {}}>
                        <Form.Item
                            label={t('preliminary-applications.fields.state')}
                            name="state_id"
                        >
                            {state?.name
                                ? state?.name
                                : formProps?.form.getFieldValue('state_id')
                                ? formProps?.form.getFieldValue('state_id')
                                : t('draft')}
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12} style={isMob ? { order: 1 } : {}}>
                        <Form.Item label={null}>
                            <ProcedureDescription
                                description={
                                    '* ' +
                                    t(
                                        'preliminary-applications.titles.procedure_registration_description_of_the_procedure_type_of_medicinal_product_reference_medicinal_product_nprocedure_renewal_description_of_the_procedure_is_not_applicable_not_active_nprocedure_amendments_description_of_the_procedure_content_of_proposed_amendments',
                                        {
                                            interpolation: {
                                                escapeValue: false,
                                            },
                                        }
                                    )
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('upload_files')}
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            style={{ marginTop: 5 }}
                            rules={
                                fileList?.length
                                    ? [{ validator: validateFiles }]
                                    : []
                            }
                        >
                            <MultiFileUploader
                                fileList={fileList}
                                onChange={handleFileChange}
                                accept=".pdf"
                                listType="text"
                            />
                        </Form.Item>

                        <AntdList
                            header={<div>{t('file_list')}</div>}
                            footer={null}
                            bordered
                            dataSource={uploaded?.map(
                                listItem => listItem.name
                            )}
                            renderItem={item => (
                                <AntdList.Item>
                                    <Text code>{item}</Text>
                                </AntdList.Item>
                            )}
                        ></AntdList>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};
