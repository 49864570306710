import { FC } from 'react';
import styles from './procedure.module.scss';

interface IProcedureDescriptionProps {
    description: string;
}

const ProcedureDescription: FC<IProcedureDescriptionProps> = ({
    description,
}) => {
    return <div className={styles.descriptionWrapper}>{description}</div>;
};

export default ProcedureDescription;
